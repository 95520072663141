import { createElement, FC, Suspense } from 'react';
import { CircularProgress } from 'legos/circular-progress';
import { Wrapper } from './styles/suspense-handler.ccm.css';

export const SuspenseHandler: FC = ({ children }) => (
  <Suspense
    fallback={
      <Wrapper.div>
        <CircularProgress color="default" size="normal" />
      </Wrapper.div>
    }
  >
    {children}
  </Suspense>
);
