
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.Wrapper_5edea74e8a3e2601{display:flex;align-items:center;justify-content:center;flex:1 0 auto;margin:20px 0}`;
styleInject(_css)

/** Wrapper Props */
export type WrapperCCM = {
  /** Wrapper Component Custom Properties */
  // No custom properties found

  /** Wrapper Modifier Flags */
  // No modifiers classes found
};
/** Base Wrapper component */
export const Wrapper: ComponentCreator<WrapperCCM> = createComponentCreator({
  "name": "Wrapper",
  "base": "Wrapper_5edea74e8a3e2601",
  "prop": {},
  "mod": {}
});

